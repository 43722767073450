import browserLang from 'browser-lang'
import { navigate } from 'gatsby'
import { withPrefix } from 'gatsby-link'
import React, { PureComponent } from 'react'
import { Helmet } from 'react-helmet'

import { languages } from './index'

class Redirect extends PureComponent {
  constructor(props) {
    super(props)

    const langCodes = Object.values(languages).map(language => language.code)
    const { langCode, redirectPage } = props.pageContext

    // Skip build, Browsers only
    if (typeof window !== 'undefined') {
      const detected =
        langCode ||
        window.localStorage.getItem('language') ||
        browserLang({
          languages: langCodes,
          fallback: 'en',
        })

      const newUrl = withPrefix(`/${detected}${redirectPage}`)
      window.localStorage.setItem('language', detected)
      navigate(newUrl, { replace: true })
    }
  }

  render() {
    return (
      <Helmet>
        <meta
          name="google-site-verification"
          content="VRkC86cRIx_mcyay4vTFAnJSoqrtd5gM7wLkuxFF-aQ"
        />
      </Helmet>
    )
  }
}

export default Redirect
